import { useUserStore } from '@/stores/user'

const redirectIfNotRibbon = () => {
  const user = useUserStore()
  if (user.data?.access?.ribbon !== true)
    window.location.href = import.meta.env.VITE_APP_URL
}

const ribbon = [
  {
    path: '/ribbon/dashboard',
    name: 'ribbon-home',
    component: () => import('@/pages/home/ribbon.vue'),
    meta: {
      title: 'Dashboard',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/integrations',
    name: 'ribbon-integrations',
    component: () => import('@/pages/ribbon/IntegrationList.vue'),
    meta: {
      title: 'Ribbon Integrations',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/sponsors',
    name: 'ribbon-sponsors',
    component: () => import('@/pages/ribbon/SponsorList.vue'),
    meta: {
      title: 'Ribbon Sponsors',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/sponsor/:sponsor_id',
    name: 'ribbon-sponsor-details',
    component: () =>
      import('@/pages/settings/sponsor-settings/AccountSettings.vue'),
    meta: {
      title: 'Sponsor Settings',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/programs',
    name: 'ribbon-programs',
    component: () => import('@/pages/ribbon/ProgramList.vue'),
    meta: {
      title: 'Ribbon Programs',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/programs/:charity_id',
    name: 'ribbon-program-details',
    component: () =>
      import('@/pages/settings/account-settings/AccountSettings.vue'),
    meta: {
      title: 'Account Settings',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/applications',
    name: 'ribbon-applications',
    component: () => import('@/pages/ribbon/ApplicationList.vue'),
    meta: {
      title: 'Applications',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/banking',
    name: 'ribbon-banking',
    component: () => import('@/pages/ribbon/SponsorBanking.vue'),
    meta: {
      title: 'Banking',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/banking-tools',
    name: 'ribbon-banking-tools',
    component: () => import('@/pages/ribbon/RibbonBankingTools.vue'),
    meta: {
      title: 'Banking Tools',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/permissions',
    name: 'ribbon-permissions',
    component: () => import('@/pages/ribbon/CharityPermissions.vue'),
    meta: {
      title: 'Charity Permissions',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
  {
    path: '/ribbon/check-management',
    name: 'ribbon-check-management',
    component: () => import('@/pages/ribbon/check-management/check-management.vue'),
    meta: {
      title: 'Check Management',
      resource: 'Ribbon',
    },
    beforeEnter() {
      redirectIfNotRibbon()
    },
  },
]

export default ribbon
